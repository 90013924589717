<template>
  <div>
    <div class="banner"
      :style="{backgroundImage: 'url(' + imgUrl+'design/home%20page/banner-chanyejishi.jpg' + ')'}"></div>
    <div class="container">
      <div v-for="(item, i) in arr" :key="i" class="item-block">
        <h4 class="flex flex-align-center">
          <i class="circle"></i>
          {{ item.name }}
        </h4>
        <div class="item-main flex">
          <div class="item-left">
            <div class="tabs">
              <div
                v-for="(n, j) in item.list"
                :key="j"
                class="tab-item"
                :class="{ active: item.activeIndex === j }"
                @click="changeTab(i, j)"
              >{{ n.name }}</div>
            </div>
          </div>
          <div class="item-right">
            <div class="tab-wrap flex">
              <img v-for="(n, j) in item.list[item.activeIndex].imgs" :key="j" :src="n" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { STATIC_URL_PRE } from '@/config';
export default {
  data() {
    return {
      imgUrl:STATIC_URL_PRE,
      arr: [
        {
          activeIndex: 0,
          name: "协作市场",
          list: [
            {
              name: "折弯",
              imgs: [
                require("../../assets/images/home/industry/zhewan/zhewan1.png"),
                require("../../assets/images/home/industry/zhewan/zhewan2.png"),
                require("../../assets/images/home/industry/zhewan/zhewan3.png"),
                require("../../assets/images/home/industry/zhewan/zhewan4.png"),
                require("../../assets/images/home/industry/zhewan/zhewan5.jpg"),
                require("../../assets/images/home/industry/zhewan/zhewan6.jpg"),
                require("../../assets/images/home/industry/zhewan/zhewan7.jpg"),
                require("../../assets/images/home/industry/zhewan/zhewan8.jpg")
              ]
            },
            {
              name: "雕花",
              imgs: [
                require("../../assets/images/home/industry/diaohua/diaohua1.jpg"),
                require("../../assets/images/home/industry/diaohua/diaohua2.jpg"),
                require("../../assets/images/home/industry/diaohua/diaohua3.jpg"),
                require("../../assets/images/home/industry/diaohua/diaohua4.jpg"),
                require("../../assets/images/home/industry/diaohua/diaohua5.jpg"),
                require("../../assets/images/home/industry/diaohua/diaohua6.jpg"),
                require("../../assets/images/home/industry/diaohua/diaohua7.jpg"),
                require("../../assets/images/home/industry/diaohua/diaohua8.jpg")
              ]
            },
            {
              name: "车葫芦",
              imgs: [
                require("../../assets/images/home/industry/chehulu/chehulu1.jpg"),
                require("../../assets/images/home/industry/chehulu//chehulu2.jpg"),
                require("../../assets/images/home/industry/chehulu//chehulu3.jpg"),
                require("../../assets/images/home/industry/chehulu//chehulu5.jpg"),
                require("../../assets/images/home/industry/chehulu//chehulu7.jpg"),
                require("../../assets/images/home/industry/chehulu//chehulu8.jpg")
              ]
            },
            {
              name: "部件白胚",
              imgs: [
                require("../../assets/images/home/industry/baipei/baipei1.jpg"),
                require("../../assets/images/home/industry/baipei/baipei2.jpg"),
                require("../../assets/images/home/industry/baipei/baipei3.jpg"),
                require("../../assets/images/home/industry/baipei/baipei4.jpg"),
                require("../../assets/images/home/industry/baipei/baipei5.jpg"),
                require("../../assets/images/home/industry/baipei/baipei6.jpg"),
                require("../../assets/images/home/industry/baipei/baipei7.jpg"),
                require("../../assets/images/home/industry/baipei/baipei8.jpg")
              ]
            },
            {
              name: "软包",
              imgs: [
                require("../../assets/images/home/industry/ruanbao/ruanbao1.jpg"),
                require("../../assets/images/home/industry/ruanbao/ruanbao2.jpg"),
                require("../../assets/images/home/industry/ruanbao/ruanbao3.jpg"),
                require("../../assets/images/home/industry/ruanbao/ruanbao4.jpg"),
                require("../../assets/images/home/industry/ruanbao/ruanbao5.jpg"),
                require("../../assets/images/home/industry/ruanbao/ruanbao6.jpg"),
                require("../../assets/images/home/industry/ruanbao/ruanbao7.jpg"),
                require("../../assets/images/home/industry/ruanbao/ruanbao8.jpg"),
              ]
            }
          ]
        },
        {
          activeIndex: 0,
          name: "备料市场",
          list: [
            {
              name: "锯材",
              imgs: [
                require("../../assets/images/home/industry/jucai/jucai1.jpg"),
                require("../../assets/images/home/industry/jucai/jucai2.jpg"),
                require("../../assets/images/home/industry/jucai/jucai3.jpg"),
                require("../../assets/images/home/industry/jucai/jucai4.jpg"),
                require("../../assets/images/home/industry/jucai/jucai5.jpg"),
                require("../../assets/images/home/industry/jucai/jucai6.jpg"),
                require("../../assets/images/home/industry/jucai/jucai7.jpg"),
                require("../../assets/images/home/industry/jucai/jucai8.jpg"),
              ]
            },
            {
              name: "规格料",
              imgs: [
                require("../../assets/images/home/industry/guigeliao/guigeliao1.jpg"),
                require("../../assets/images/home/industry/guigeliao/guigeliao2.jpg"),
                require("../../assets/images/home/industry/guigeliao/guigeliao3.jpg"),
                require("../../assets/images/home/industry/guigeliao/guigeliao4.jpg"),
                require("../../assets/images/home/industry/guigeliao/guigeliao5.jpg"),
                require("../../assets/images/home/industry/guigeliao/guigeliao6.jpg"),
                require("../../assets/images/home/industry/guigeliao/guigeliao7.jpg"),
                require("../../assets/images/home/industry/guigeliao/guigeliao8.jpg"),
              ]
            },
            {
              name: "齿接板",
              imgs: [
                require("../../assets/images/home/industry/chijie/chijieban1.jpg"),
                require("../../assets/images/home/industry/chijie/chijieban2.jpg"),
                require("../../assets/images/home/industry/chijie/chijieban3.jpg"),
                require("../../assets/images/home/industry/chijie/chijieban4.jpg"),
                require("../../assets/images/home/industry/chijie/chijieban5.jpg"),
                require("../../assets/images/home/industry/chijie/chijieban6.jpg"),
                require("../../assets/images/home/industry/chijie/chijieban7.jpg"),
                require("../../assets/images/home/industry/chijie/chijieban8.jpg"),
              ]
            },
            {
              name: "直拼板",
              imgs: [
                require("../../assets/images/home/industry/zhipinban/zhipinban1.jpg"),
                require("../../assets/images/home/industry/zhipinban/zhipinban2.jpg"),
                require("../../assets/images/home/industry/zhipinban/zhipinban3.jpg"),
                require("../../assets/images/home/industry/zhipinban/zhipinban4.jpg"),
                require("../../assets/images/home/industry/zhipinban/zhipinban5.jpg"),
                require("../../assets/images/home/industry/zhipinban/zhipinban6.jpg"),
                require("../../assets/images/home/industry/zhipinban/zhipinban7.jpg"),
                require("../../assets/images/home/industry/zhipinban/zhipinban8.jpg"),
              ]
            }
          ]
        },
        {
          activeIndex: 0,
          name: "设计市场",
          list: [
            {
              name: "智能家具",
              imgs: [
                require("../../assets/images/home/industry/zhinengjiaju/zhinengjiaju1.jpg"),
                require("../../assets/images/home/industry/zhinengjiaju/zhinengjiaju2.jpg"),
                require("../../assets/images/home/industry/zhinengjiaju/zhinengjiaju3.jpg"),
                require("../../assets/images/home/industry/zhinengjiaju/zhinengjiaju4.jpg"),
                require("../../assets/images/home/industry/zhinengjiaju/zhinengjiaju5.jpg"),
                require("../../assets/images/home/industry/zhinengjiaju/zhinengjiaju6.jpg"),
                require("../../assets/images/home/industry/zhinengjiaju/zhinengjiaju7.jpg"),
                require("../../assets/images/home/industry/zhinengjiaju/zhinengjiaju8.jpg"),
              ]
            }
          ]
        }
      ]
    };
  },
  methods: {
    changeTab(m, n) {
      this.arr[m].activeIndex = n;
    }
  }
};
</script>
<style scoped lang="less">
.container {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 177px;
}
.banner {
  height: 420px;
  // background: url("../../assets/images/home/banner-chanyejishi.jpg") center
  //   center no-repeat;
  background-position: center;
  background-size: cover;
}
.item-block {
  background-color: #fff;
  margin-top: 28px;
  &:first-child {
    margin-top: 0;
  }
  h4 {
    font-size: 24px;
    color: #292929;
    padding: 31px 26px;
    line-height: 1;
    .circle {
      width: 12px;
      height: 12px;
      border: 3px solid #5074ee;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
  .item-main {
    padding: 17px 31px 31px 45px;
    .item-left {
      width: 140px;
      height: 510px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(169, 182, 225, 1);
      .tabs {
        margin-left: -1px;
        padding-top: 15px;
        .tab-item {
          width: 100px;
          height: 40px;
          background: rgba(169, 182, 225, 1);
          margin-bottom: 30px;
          color: #fff;
          padding-left: 20px;
          line-height: 40px;
          cursor: pointer;
          &.active {
            background: rgba(80, 116, 238, 1);
            box-shadow: 0px 2px 4px 0px rgba(169, 182, 225, 1);
          }
        }
      }
    }
    .item-right {
      width: 984px;
      height: 510px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(230, 230, 230, 1);
      border-left: 0;
      .tab-wrap {
        padding: 30px 0 0 30px;
        img {
          margin: 0 28px 30px 0;
          width: 210px;
          height: 210px;
        }
      }
    }
  }
}
</style>
